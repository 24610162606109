.card-container {
  background: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 5px 5px rgba(0, 0, 0, 0.226);
  margin: 2rem 1rem;
  max-width: 30rem;
}

.header {
  flex: 1;
  width: 100%;
}

.header-title {
  color: #004aad;
  margin: 1.5rem auto;
  padding-left: 1rem;
  border-bottom: 1px solid #a7a7a7;
  width: 95%;
  padding-bottom: 2px;
  font-size: 3rem;
}

.content-container {
  flex: 3;
  width: 100%;
}

.subtitle {
  margin: 1rem auto;
}

h2 {
  color: black;
  font-size: 3.5rem;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0.2rem;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.functionality {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  margin: 0.5rem 2.5rem;
  letter-spacing: 0.05rem;
}

h3 {
  color: black;
  font-weight: 600;
  font-size: 1.2rem;
  margin-left: 1rem;
}

.footer {
  flex: 1;
  background-color: #8fb2f1;
  width: 100%;
  border-radius: 0px 0px 20px 20px;
  padding: 1rem 0;
  max-height: 4rem;
}

h4 {
  text-align: center;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
}
