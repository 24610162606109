.slides {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background: linear-gradient(180deg, #004aad 26.04%, #4bc2f4 100%);
}

#first-section {
  height: fit-content;
  justify-content: start;
}

.main-mock {
  z-index: 1;
}

.spacer {
  aspect-ratio: 450/100;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../images/firstWave.svg");
}

.main-cta-button {
  background-color: #ffd130;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  color: black;
  padding: 1.5rem 1.75rem;
  font-weight: 700;
  letter-spacing: 0.15rem;
  margin: 5rem 0rem;
}

#second-section {
  background: white;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

h1 {
  letter-spacing: 0.15rem;
  font-size: 2rem;
  font-weight: 600;
  text-align: left;
  margin: 3rem 2rem 0rem;
}

.second-section-title {
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.second-section-title h1 {
  color: #505050;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 20px;
  letter-spacing: 0.1em;
  text-align: center;
}

.text-block {
  background-color: #8fb2f1;
  font-weight: 500;
  margin: 0 4rem;
  padding: 1rem;
  border-radius: 20px;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  min-width: 80%;
}

.spacer-reverse {
  aspect-ratio: 450/100;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../images/skinnyDarkerWave.svg");
}

#pricing-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #004aad;
  height: fit-content;
  padding-top: 4rem;
  overflow: hidden;
}

.pricing-card-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pricing-section-title {
  font-size: 2.5rem;
  color: white;
  text-align: center;
  line-height: 3rem;
}

#fourth-section {
  background: #004aad;
  height: fit-content;
  padding: 10rem auto;
}

/* Desktops and tablets */
@media screen and (min-width: 1200px) {
  .pricing-card-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
