#third-section {
  height: fit-content;
  justify-content: space-around;
  background: linear-gradient(180deg, #004aad 26.04%, #004aad 100%);
}

.third-section-title {
  flex: 1;
}

.functionality-section {
  display: flex;
  flex-direction: column;
  flex: 4;
  justify-content: center;
  align-items: center;
}

.single-functionality {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  color: white;
}

.functionality-text {
  color: white;
  font-weight: 600;
  text-align: center;
  margin: 1rem 4rem;
}
